import React, { useState, useContext } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { SocketContext } from 'react-socket-io';
import { connect } from 'react-redux';

import Loader from '../../../../shared/Loader';
import CameraSettings from './CameraSettings';
import GphotoSettings from './GphotoSettings';
import { displaySuccessModal, displayErrorModal } from '../../../../helpers/swal';

import { updateBcapSettings } from '../api';

import { fetchCamera } from '../actions';

import style from './style.module.scss';

const FormComponent = ({ camera, fetchCameraAction }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const socket = useContext(SocketContext);
  const methods = useForm();

  const getSettingsHandler = () => socket.emit('get CamSettings', camera.cameraKey);
  const resetPTUHandler = () => socket.emit('reset ptu', camera.cameraKey);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const cameraObj = {};
    const { cameraSettings, gPhotoSettings } = data;
    const { cameraKey } = camera;

    try {
      cameraObj.camera = cameraKey;
      cameraObj.cameraSettings = cameraSettings;
      cameraObj._id = camera._id;
      cameraObj.gPhotoSettings = {};

      if (camera.gphotoConfigs.length) {
        const manufacturer = camera.gphotoConfigs.find((item) => item.configKey === 'manufacturer') || { configValue: 'Canon' };
        const cameraManufacturer = manufacturer.configValue.includes('Canon') ? 'canon' : 'sony';
        cameraObj.gPhotoSettings[cameraManufacturer] = gPhotoSettings;
      }

      await updateBcapSettings(cameraKey, cameraObj);
      fetchCameraAction(cameraKey);

      setIsSubmitting(false);
      displaySuccessModal({ text: `${cameraKey} updated successfully`, timer: 5000 });
    } catch (err) {
      setIsSubmitting(false);
      displayErrorModal({ text: `Error trying to updated ${cameraKey}, try again`, timer: 5000 });
      console.error('Error trying to update config', err);
    }
  };

  return (
    <div className={style.FormWrapper}>
      {isSubmitting && <Loader />}
      <div className={style.FormHeader}>
        <h5>{`${camera.cameraKey} settings`}</h5>
      </div>
      <div>
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CameraSettings
              camera={camera}
              register={methods.register}
              setValue={methods.setValue}
            />
            {camera.gphotoConfigs.length > 0 && (
              <GphotoSettings
                camera={camera}
                register={methods.register}
                setValue={methods.setValue}
              />
            )}
            <div className={style.buttonWrapper}>
              <Button className="small" variant="outline-primary" onClick={resetPTUHandler}>
                Reset PTU
              </Button>
              <Button className="small" variant="outline-primary" onClick={getSettingsHandler}>
                Get Settings
              </Button>
              <Button className="small" variant="outline-primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </FormContext>
      </div>
    </div>
  );
};
export default connect(
  null,
  { fetchCameraAction: fetchCamera },
)(FormComponent);
