/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';

import Authorize, { can } from '../../../components/Authorize';

import { setCurrentTab } from '../actions';
import { MAGICSHOTS_READ } from '../../../components/Authorize/permissions/resources';
import { PREMIUM_SNAPSHOTS } from './permissions';

const Stats = (props) => {
  const {
    error,
    favorite,
    all,
    magicshots,
    shared,
    premium,
    setCurrentTabAction,
    hasPremiumEnabled,
  } = props;

  if (error) {
    return <p>Error loading album data.</p>;
  }

  return (
    <Nav variant="tabs tabs-alt" defaultActiveKey="all" onSelect={setCurrentTabAction}>
      <Nav.Item>
        <Nav.Link eventKey="all">All Photos ({all})</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="shared" disabled={!shared}>Shared ({shared})</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="favorite" disabled={!favorite}>Favorites ({favorite})</Nav.Link>
      </Nav.Item>
      <Authorize permissions={MAGICSHOTS_READ}>
        <Nav.Item>
          <Nav.Link eventKey="magicshots" disabled={!magicshots}>Magicshots ({magicshots})</Nav.Link>
        </Nav.Item>
      </Authorize>
      {hasPremiumEnabled && can(PREMIUM_SNAPSHOTS) && (
        <Nav.Item>
          <Nav.Link eventKey="premium" disabled={!premium}>Premium ({premium})</Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default connect(
  ({
    album: {
      stats: {
        error,
        favorite,
        all,
        magicshots,
        shared,
        premium,
      },
    },
    events: {
      selectedEvents,
    },
  }) => ({
    error,
    favorite,
    all,
    magicshots,
    shared,
    premium,
    hasPremiumEnabled: selectedEvents.some(({ payment }) => payment && payment.enabled === true),
  }),
  { setCurrentTabAction: setCurrentTab },
)(Stats);
