import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import { toggleB2CSettings } from '../actions';

const GeolocationToggle = ({ eventKey, b2cEnabled, toggleB2CSettingsAction }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, toggleB2CSettingsAction);

  return (
    <Button variant="link pr-0">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switcher labelFirst">
        <span className="switcher-label">{b2cEnabled ? 'Enabled' : 'Disabled'}</span>
        <input type="checkbox" checked={b2cEnabled} className="switcher-input" onChange={decoratedOnClick} />
        <span className="switcher-indicator">
          <span className="switcher-yes" />
          <span className="switcher-no" />
        </span>
      </label>
    </Button>
  );
};

export default connect(
  ({ b2c }) => ({ b2cEnabled: b2c.enabled }),
  { toggleB2CSettingsAction: toggleB2CSettings },
)(GeolocationToggle);
