import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import { getPlatformIcon } from './appIcons';

import style from './style.module.scss';

const ShareOption = ({
  onChange,
  toggleEnabled,
  sharePlatform,
}) => (
  <div className={style.shareOption}>
    <FontAwesomeIcon
      icon={getPlatformIcon(sharePlatform)}
      className={classNames(style.icon, style[sharePlatform.toLowerCase()])}
    />
    <span className={style.platform}>{sharePlatform}</span>
    <Button variant="link pr-0">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switcher labelFirst mr-0">
        <input type="checkbox" defaultChecked={toggleEnabled} className="switcher-input" onChange={onChange} />
        <span className="switcher-indicator">
          <span className="switcher-yes" />
          <span className="switcher-no" />
        </span>
        <span className="switcher-label">Paid</span>
      </label>
    </Button>
    <FontAwesomeIcon icon={faGripVertical} size="sm" className={style.dragIcon} />
  </div>
);

export default ShareOption;
