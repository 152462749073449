import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { SocketContext } from 'react-socket-io';
import { isEmpty, findIndex } from 'lodash';

import { closeBlockedSeatModal, addBlockedSeat } from '../actions';

import style from '../style.module.scss';

const defaultValues = {
  seatType: '',
  section: '',
  row: '',
  seat: '',
};

const BlockedSeatModal = ({
  event,
  blockedSeats,
  isBlockedSeatModalOpen,
  closeBlockedSeatModalAction,
  addBlockedSeatAction,
}) => {
  const socket = useContext(SocketContext);
  const [sections, setSections] = useState([]);
  const [rows, setRows] = useState([]);
  const [seats, setSeats] = useState([]);
  const [isRepeatedSRS, setIsRepeatedSRS] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
  } = useForm(defaultValues);

  if (isEmpty(event)) {
    return null;
  }
  const closeHandler = () => {
    reset();
    closeBlockedSeatModalAction();
  };

  const submitHandler = () => {
    setIsRepeatedSRS(false);
    const isSeatBlocked = findIndex(blockedSeats, getValues()) !== -1;

    if (isSeatBlocked) {
      setIsRepeatedSRS(true);
      return;
    }

    addBlockedSeatAction(getValues());
    closeHandler();
  };

  const availableSeatTypes = Object.keys(event.seatOptions).filter((seatType) => {
    const { enabled, forcedDisable } = event.seatOptions[seatType];
    return enabled || forcedDisable;
  });

  if (!availableSeatTypes.length) {
    return null;
  }

  const getSections = () => {
    const { seatType } = getValues();
    socket.emit('getSections', seatType, (result) => {
      setSections(result);
    });
  };

  const getRows = () => {
    const { seatType, section } = getValues();
    socket.emit('getRows', seatType === 'stand' ? 'section' : seatType, section, (result) => {
      setRows(result);
    });
  };

  const getSeats = () => {
    const { seatType, section, row } = getValues();
    socket.emit('getSeats', seatType === 'stand' ? 'section' : seatType, section, row, (result) => {
      setSeats(result);
    });
  };

  return (
    <Modal
      show={isBlockedSeatModalOpen}
      onHide={closeBlockedSeatModalAction}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3" className="w-100 text-center">Add blocked seats</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="seatTypeInput">
          <Form.Label>Seat Type</Form.Label>
          <RHFInput
            name="seatType"
            as={(
              <Form.Control as="select" size="lg" custom>
                <option value="" disabled>Select a seat type</option>
                {availableSeatTypes.map((item) => (<option key={item}>{item}</option>))}
              </Form.Control>
            )}
            register={register({ required: true })}
            setValue={setValue}
            onChange={getSections}
          />
          {errors.seatType && (
            <span className={style.inputError}>Please, select a seat type</span>
          )}

        </Form.Group>

        <Form.Group controlId="sectionInput">
          <Form.Label>Section</Form.Label>
          <RHFInput
            name="section"
            as={(
              <Form.Control as="select" size="lg" custom>
                <option value="" disabled>Select a seat</option>
                {sections.map((item) => (<option key={item}>{item}</option>))}
              </Form.Control>
            )}
            register={register({ required: true })}
            setValue={setValue}
            onChange={getRows}
          />
          {errors.section && (
            <span className={style.inputError}>Please, select a section</span>
          )}
        </Form.Group>

        <Form.Group controlId="rowInput">
          <Form.Label>Row</Form.Label>

          <RHFInput
            name="row"
            as={(
              <Form.Control as="select" size="lg" custom>
                <option value="" disabled>Select a row</option>
                {rows.map((item) => (<option key={item}>{item}</option>))}
              </Form.Control>
            )}
            register={register({ required: true })}
            setValue={setValue}
            onChange={getSeats}
          />
          {errors.row && (
            <span className={style.inputError}>Please, select a row</span>
          )}
        </Form.Group>

        <Form.Group className="mb-0" controlId="seatInput">
          <Form.Label>Seat</Form.Label>
          <RHFInput
            name="seat"
            as={(
              <Form.Control as="select" size="lg" custom>
                <option value="" disabled>Select a seat</option>
                {seats.map((item) => (<option key={item}>{item}</option>))}
              </Form.Control>
            )}
            register={register({ required: true })}
            setValue={setValue}
          />
          {errors.seat && (
            <span className={style.inputError}>Please, select a seat</span>
          )}
        </Form.Group>
        {isRepeatedSRS && (
          <span className={style.inputError}>
            This SRS is already blocked, please, try a different one
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="small" variant="outline-primary" onClick={closeHandler}>
          Cancel
        </Button>
        <Button className="small" variant="primary" type="submit" onClick={handleSubmit(submitHandler)}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  (
    { events: { selectedEvents }, blockedSeats: { isBlockedSeatModalOpen, blockedSeats } },
  ) => (
    { isBlockedSeatModalOpen, blockedSeats, event: selectedEvents[0] }
  ),
  { closeBlockedSeatModalAction: closeBlockedSeatModal, addBlockedSeatAction: addBlockedSeat },
)(BlockedSeatModal);
