import {
  TOGGLE_B2C_SETTINGS,
  SET_B2C_SETTINGS,
  UPDATE_B2C_SETTING,
} from './types';

export const toggleB2CSettings = () => ({ type: TOGGLE_B2C_SETTINGS });

export const setB2CSettings = (payload) => ({
  type: SET_B2C_SETTINGS,
  payload,
});

export const updateB2CSetting = (payload) => ({
  type: UPDATE_B2C_SETTING,
  payload,
});
