import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';

import { updateB2CSetting } from '../actions';

import style from '../style.module.scss';

const B2CForm = ({ b2c, updateB2CSettingAction }) => {
  const changeHandler = (data) => {
    updateB2CSettingAction({ [data.target.name]: data.target.type === 'checkbox' ? data.target.checked : data.target.value });
  };

  return (
    <div className={style.accordionBodyWrapper}>
      <Form>
        <Form.Group controlId="currency">
          <Form.Label>Currency</Form.Label>
          <Form.Control custom as="select" name="currency" onChange={changeHandler} value={b2c.currency}>
            <option>USD</option>
            <option>CAD</option>
            <option>EUR</option>
            <option>JPY</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="defaultPrice">
          <Form.Label>
            <span>Default Price</span>
            <OverlayTrigger
              placement="right"
              overlay={(
                <Popover id="b2c">
                  <Popover.Content>will be used for PATH A when AB Testing</Popover.Content>
                </Popover>
              )}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            as="input"
            type="number"
            min={0}
            name="defaultPrice"
            value={b2c.defaultPrice}
            onChange={changeHandler}
          />
        </Form.Group>

        <Form.Group controlId="priceList">
          <Form.Label>
            <span>Price list</span>
            <OverlayTrigger
              placement="right"
              overlay={(
                <Popover id="b2c">
                  <Popover.Content>
                    To use price list, make sure ABTest is disable.
                    The list must be comma separated
                  </Popover.Content>
                </Popover>
              )}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="priceList"
            onChange={changeHandler}
            value={b2c.priceList}
          />
        </Form.Group>

        <Form.Group controlId="ABTestEnabled">
          <Form.Label>Enable AB Test</Form.Label>
          <Button variant="link" className="p-0 ml-auto">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="switcher labelFirst">
              <input
                type="checkbox"
                name="ABTestEnabled"
                checked={b2c.ABTestEnabled}
                className="switcher-input"
                onChange={changeHandler}
              />
              <span className="switcher-indicator">
                <span className="switcher-yes" />
                <span className="switcher-no" />
              </span>
              <span className="switcher-label">{b2c.ABTestEnabled ? 'Enabled' : 'Disabled'}</span>
            </label>
          </Button>
        </Form.Group>

        <Form.Group controlId="alternativePrice">
          <Form.Label>
            <span>Alternative Price</span>
            <OverlayTrigger
              placement="right"
              overlay={(
                <Popover id="b2c">
                  <Popover.Content>will be used for PATH B when AB Testing</Popover.Content>
                </Popover>
              )}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            as="input"
            type="number"
            min={0}
            name="alternativePrice"
            onChange={changeHandler}
            value={b2c.alternativePrice}
          />
        </Form.Group>
      </Form>
      <div className={style.description}>
        Stripe API expect amounts to be provided in a currency´s smallest unit.
        <br />
        ie: USD 1.00 = 100, CAD 1.00 = 100, EUR 1.00 = 100, JPY 100 = 100
        <br />
        <a href="https://stripe.com/docs/currencies" target="_blank" rel="noopener noreferrer">Click here to read more about it</a>
      </div>
    </div>
  );
};

export default connect(
  ({ b2c }) => ({ b2c }),
  { updateB2CSettingAction: updateB2CSetting },
)(B2CForm);
