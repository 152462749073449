import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';

import Controls from './Controls';
import { setSelectedFaceInd } from '../actions';
import { resizeObserver } from '../../../../helpers/browser';

import style from './style.module.scss';

const setImgWrapperSize = (domEl) => {
  if (!domEl) {
    return;
  }

  const size = Math.min(domEl.clientHeight, domEl.clientWidth);
  domEl.parentNode.style.setProperty('--imgSize', `${size}px`);
};

const Image = ({
  hidden,
  mediumURI,
  largeImageUrl,
  imageMiningData = [],
  displayFaceBoxes,
  selectedFaceInd,
  setSelectedFaceIndAction,
}) => {
  const imgRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    requestAnimationFrame(() => setImgWrapperSize(imgRef.current));
  }, [imgRef.current]);

  useEffect(() => resizeObserver(imgRef.current, setImgWrapperSize), [imgRef.current]);

  return (
    <div className={style.imgWrapper}>
      {isLoading && <Spinner animation="border" variant="primary" className={style.spinner} />}

      <img id="lightboxImage" ref={imgRef} src={hidden ? mediumURI : largeImageUrl} alt="Snapshot" onLoad={() => setIsLoading(false)} />

      <div className={style.facesData}>
        {displayFaceBoxes && !isLoading
          && imageMiningData.map(({ BoundingBox: boundingBox }, ind) => {
            const top = `${boundingBox.Top * 100}%`;
            const left = `${boundingBox.Left * 100}%`;
            const height = `${boundingBox.Height * 100}%`;
            const width = `${boundingBox.Width * 100}%`;

            return (
              // eslint-disable-next-line
              <div
                key={JSON.stringify(boundingBox)}
                className={classnames(
                  style.faceBoundingBox,
                  { [style.active]: ind === selectedFaceInd },
                )}
                style={{
                  top,
                  left,
                  width,
                  height,
                  cursor: 'pointer',
                  zIndex: 3,
                }}
                onClick={() => setSelectedFaceIndAction(ind)}
              />
            );
          })}
      </div>
      <Controls />
    </div>
  );
};

export default connect(
  ({
    lightbox: {
      snapshot: {
        data: { hidden, mediumURI },
        largeImageUrl,
        imageMiningData: {
          data: imageMiningData,
          selectedInd: selectedFaceInd,
        },
      },
      info: {
        imageMiningData: {
          open: displayFaceBoxes,
        },
      },
    },
  }) => ({
    hidden,
    mediumURI,
    largeImageUrl,
    imageMiningData,
    displayFaceBoxes,
    selectedFaceInd,
  }),
  { setSelectedFaceIndAction: setSelectedFaceInd },
)(Image);
