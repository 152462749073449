import React from 'react';
import { connect } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { GA_CHARTS_RESULTS } from '../consts';

const RegionsChart = ({ gaRegion }) => {
  const chartData = gaRegion.slice(0, GA_CHARTS_RESULTS).map((item) => ({
    name: item.category,
    percentage: item.value,
    label: `${item.value.toFixed(2)}%`,
  }));

  return (
    <GAChartWrapper
      chartType="horizontal"
      cardTitle={(
        <Info
          label="Regions"
          content="The percentage of users who come from a given geographical region based on Google Analytics data"
          className="ml-2"
        />
      )}
      chartData={chartData}
      fallbackTitle="regions"
    />
  );
};

export default connect(
  ({ analytics: { ga: { data: { gaRegion } } } }) => ({ gaRegion }),
)(RegionsChart);
