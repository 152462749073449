import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { isUndefined } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

import Snapshot from '../../index';

import { resizeObserver } from '../../../../helpers/browser';
import { fetchListItems, setFullscreenSnapshot } from './actions';
import { useLoadingObserver } from './hooks';
import { toggleUserPhotosId } from '../actions';

import style from './style.module.scss';

let scrollLeft;
let scrollWidth;
let prevThumbSize;

const setThumbSize = (domEl) => {
  if (!domEl) {
    return undefined;
  }

  // 2 * 3 for thumbnail margin and 28 + 10 for element padding
  const containerHeight = domEl.clientHeight - 44;
  domEl.style.setProperty('--thumbSize', `${containerHeight}px`);

  return domEl.getBoundingClientRect().height - 44;
};

const SnapshotWrapper = ({
  snapshot,
  selectedId,
  userId,
  onClick,
  label,
}) => {
  const { _id } = snapshot;
  const selected = _id === selectedId;
  const ref = useRef();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selected) {
      const timeout = setTimeout(() => {
        ref.current.scrollIntoView({ inline: 'center' });
      });

      return () => {
        clearTimeout(timeout);
      };
    }
    // we need to scroll again on user photos toggle
  }, [userId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selected) {
      const timeout = setTimeout(() => {
        ref.current.scrollIntoView({ inline: 'nearest' });
      });

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [selectedId]);

  return (
    <div ref={ref} className="position-relative">
      <span className={style.snapshotDate}>{label}</span>
      <Snapshot
        descriptionEnabled
        snapshot={snapshot}
        selected={selected}
        onClick={onClick}
      />
    </div>
  );
};

const Carousel = ({
  isFetching,
  allLoadedRight,
  currentPageLeft,
  snapshots,
  itemsPerPage,
  selectedId,
  userId,
  setFullscreenSnapshotAction,
  fetchListItemsAction,
  toggleUserPhotosIdAction,
}) => {
  const containerRef = useRef();
  const leftLoaderRef = useRef();
  const rightLoaderRef = useRef();
  const fetchedListRef = useRef();
  const [
    isLeftLoading,
    isRightLoading,
    unsetLoading,
  ] = useLoadingObserver(
    leftLoaderRef,
    rightLoaderRef,
    containerRef,
    snapshots,
    itemsPerPage,
    currentPageLeft,
  );

  useEffect(() => {
    fetchedListRef.current = false;
  }, [userId]);

  useEffect(() => {
    if (!fetchedListRef.current && snapshots.length === 0) {
      fetchedListRef.current = true;
      fetchListItemsAction();
    }
  }, [snapshots.length]);

  useEffect(() => {
    if (isLeftLoading) {
      if (currentPageLeft > 1) {
        const loadLeft = true;
        fetchListItemsAction(loadLeft);
      } else {
        unsetLoading();
      }
    }
  }, [isLeftLoading]);

  useEffect(() => {
    if (isRightLoading) {
      if (!allLoadedRight) {
        fetchListItemsAction();
      } else {
        unsetLoading();
      }
    }
  }, [isRightLoading]);

  useEffect(() => {
    if (!isFetching) {
      unsetLoading();
    }
  }, [isFetching]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    requestAnimationFrame(() => setThumbSize(containerRef.current));

    if (containerRef.current) {
      const scrollHandler = (evt) => {
        ({ scrollLeft, scrollWidth } = evt.target);
      };

      containerRef.current.addEventListener('scroll', scrollHandler);
      return () => containerRef.current.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  useEffect(() => resizeObserver(containerRef.current, (domEl) => {
    const thumbSize = setThumbSize(domEl);
    let thumbDelta = 0;

    if (thumbSize) {
      thumbDelta = prevThumbSize ? thumbSize - prevThumbSize : 0;
      prevThumbSize = thumbSize;
    }

    const ratio = scrollLeft / scrollWidth;
    scrollLeft = domEl.scrollWidth * (ratio + thumbDelta / 10000);
    // eslint-disable-next-line no-param-reassign
    domEl.scrollLeft = scrollLeft;
  }), [containerRef.current]);

  useEffect(() => {
    const handler = (evt) => {
      const selectedIndex = snapshots.findIndex(({ _id }) => _id === selectedId);
      const preIndex = selectedIndex - 1;
      const nextIndex = selectedIndex + 1;

      if (evt.key === 'ArrowRight' && !isUndefined(snapshots[nextIndex])) {
        const { _id } = snapshots[nextIndex];
        setFullscreenSnapshotAction(_id);
      }

      if (evt.key === 'ArrowLeft' && !isUndefined(snapshots[preIndex])) {
        const { _id } = snapshots[preIndex];
        setFullscreenSnapshotAction(_id);
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [selectedId]);

  return (
    <div ref={containerRef} className={style.snapshots}>
      <div className={style.toggleTab}>
        <button
          type="button"
          onClick={toggleUserPhotosIdAction}
          className={classnames({ [style.active]: !userId })}
        >
          All Photos
        </button>
        <button
          type="button"
          onClick={toggleUserPhotosIdAction}
          className={classnames({ [style.active]: userId })}
        >
          User
        </button>
      </div>
      {isFetching && snapshots.length === 0 && (
        <div className={style.spinnerWrapper}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <div className={style.loadingIndicatorAnimated}>
        <div ref={leftLoaderRef} className={classnames({ [style.hidden]: !isLeftLoading })}>
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
      {/*
        * doing the mapping here because we don't want to map it in mapStateToProps (connect)
        * if we map it in mapStateToProps, it would cause our component to change
        * on every Redux state update since map function is returning a new array instance.
      */}
      {snapshots.map((snapshot, idx) => {
        const prev = snapshots[idx - 1] || {};
        const { _id: snapshotId, slugDate, slug } = snapshot;
        let showLabel;

        if (prev.slugDate !== slugDate) {
          const date = slugDate.replace(`${slug}-`, '');
          showLabel = moment(date).format('LL');
        }

        return (
          <SnapshotWrapper
            key={snapshotId}
            label={showLabel}
            userId={userId}
            snapshot={snapshot}
            selectedId={selectedId}
            onClick={setFullscreenSnapshotAction}
          />
        );
      })}
      <div className={style.loadingIndicator}>
        <div ref={rightLoaderRef} className={classnames({ [style.hidden]: !isRightLoading })}>
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({
    lightbox: {
      snapshotList: {
        isFetching,
        allLoadedRight,
        currentPageLeft,
        items: snapshots,
        itemsPerPage,
        userId,
      },
      snapshot: {
        data: {
          _id: selectedId,
        } = {},
      },
    },
  }) => ({
    isFetching,
    allLoadedRight,
    currentPageLeft,
    snapshots,
    selectedId,
    itemsPerPage,
    userId,
  }),
  {
    setFullscreenSnapshotAction: setFullscreenSnapshot,
    fetchListItemsAction: fetchListItems,
    toggleUserPhotosIdAction: toggleUserPhotosId,
  },
)(Carousel);
