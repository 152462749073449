import {
  FETCH_SNAPSHOT_STATS,
  RESET_SNAPSHOT_STATS,
  ADD_SNAPSHOT_TO_STATS,
  REMOVE_SNAPSHOTS_FROM_STATS,
  CHANGE_FAVORITE_ON_STATS,
  INCREMENT_SHARED,
} from '../types';

const initialState = {
  error: null,
  favorite: 0,
  all: 0,
  magicshots: 0,
  shared: 0,
  premium: 0,
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case FETCH_SNAPSHOT_STATS: {
    const { error, data = {} } = payload;

    return {
      ...state,
      error,
      ...data,
    };
  }

  case RESET_SNAPSHOT_STATS: {
    return { ...initialState };
  }

  case ADD_SNAPSHOT_TO_STATS: {
    const { magicshot, premium } = payload;

    return {
      ...state,
      all: state.all + Number(!magicshot),
      // Number called will boolean argument will return 1 for true and 0 for false
      magicshots: state.magicshots + Number(magicshot),
      premium: state.premium + Number(premium),
    };
  }

  case REMOVE_SNAPSHOTS_FROM_STATS: {
    const {
      all,
      favorite,
      magicshot,
      premium,
      shared,
    } = payload;

    return {
      ...state,
      all: state.all - all,
      favorite: state.favorite - favorite,
      magicshots: state.magicshots - magicshot,
      premium: state.premium - premium,
      shared: state.shared - shared,
    };
  }

  case CHANGE_FAVORITE_ON_STATS: {
    return {
      ...state,
      favorite: state.favorite + payload,
    };
  }

  case INCREMENT_SHARED: {
    return {
      ...state,
      shared: state.shared + 1,
    };
  }

  default: {
    return state;
  }
  }
};
