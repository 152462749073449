import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Accordion, Card, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import EventSelectorButton from '../../../components/EventSelector/Button';
import UpdateButton from '../UpdateButton';
import LocationModal from './LocationOptionModal';
import GeofenceAddressForm from './GeofenceAddressForm';
import Map from './Map';
import LocationOptions from './LocationOptions';
import Loader from '../../../shared/Loader';
import EventSelector from '../../../components/EventSelector/Modal';
import GeolocationToggle from './GeolocationToggle';

import { updateEventGeofence } from '../../../helpers/api';
import { displayErrorModal } from '../../../helpers/swal';
import { loadGeofenceSettings } from './actions';
import { useUpdateEvent } from '../hooks';

import style from './style.module.scss';

const validate = (geofence) => () => {
  const error = [];

  if (geofence.enabled) {
    if (geofence.radiusInput === '') {
      error.push('Radius is required');
    } else if (geofence.radius.toString() !== geofence.radiusInput) {
      error.push('Radius value does not match the map');
    }

    if (geofence.addressInput === '') {
      error.push('Address is required');
    } else if (geofence.address !== geofence.addressInput) {
      error.push('Address value does not match the map');
    }

    if (!geofence.locations.some((location) => location.allow)) {
      error.push('There must be at least a single location that allows user access');
    }
  }

  if (error.length) {
    displayErrorModal({
      title: 'Geofence is not configured properly',
      html: <div>{error.map((item) => <div>{item}</div>)}</div>,
    });
    return false;
  }
  return true;
};

const Analytics = ({
  geofenceEnabled,
  geofence,
  selectedEvents,
  isFetching,
  loadGeofenceSettingsAction,
}) => {
  const isEventUpdating = useUpdateEvent();

  const updateEventHandler = (eventIds) => {
    const data = { eventIds, geofence };

    return updateEventGeofence(data);
  };

  useEffect(() => {
    if (selectedEvents.length && !isEventUpdating) {
      const [currentEvent] = selectedEvents;
      loadGeofenceSettingsAction(currentEvent.geofence);
    }
  }, [selectedEvents, isEventUpdating]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="pageHeader">
        <h4>Event Settings</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton validation={validate(geofence)} label="Update Event" updateHandler={updateEventHandler} />
      </div>
      <div className={classNames('d-flex', 'flex-column', 'flex-grow-1', style.mainContent)}>
        {isFetching && (<Loader />)}

        {isEventUpdating && (
          <div className="flex-grow-1 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!isEventUpdating && (
          <Accordion activeKey={geofenceEnabled ? '0' : null}>
            <Card>
              <Card.Header className={style.accordionHeader}>
                <h4>Geofencing Settings</h4>
                <GeolocationToggle eventKey="0" />
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="d-flex">
                  <div className={style.infoContainer}>
                    <GeofenceAddressForm />
                    <LocationOptions />
                  </div>
                  <Map />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}

        <LocationModal />
      </div>
      <EventSelector singleSelection showTemplate showFutureEvents />
    </div>
  );
};

export default connect(
  ({
    events: { template, selectedEvents },
    location: { geofence, isFetching },
  }) => ({
    template,
    geofenceEnabled: geofence.enabled,
    geofence,
    isFetching,
    selectedEvents,
  }), {
    loadGeofenceSettingsAction: loadGeofenceSettings,
  },
)(Analytics);
