import React from 'react';
import { connect } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { GA_CHARTS_RESULTS } from '../consts';

const InMarketChart = ({ gaInMarket }) => {
  const chartData = gaInMarket.slice(0, GA_CHARTS_RESULTS).map((item) => ({
    name: item.category,
    percentage: item.value,
    label: `${item.value.toFixed(2)}%`,
  }));

  return (
    <GAChartWrapper
      chartType="horizontal"
      cardTitle={(
        <Info
          label="Market"
          content="The percentage of users who are interested in market segments based on Google Analytics data. Users in these segments are more likely to be ready to purchase products or services in the specified category"
          className="ml-2"
        />
      )}
      chartData={chartData}
      fallbackTitle="in-market"
    />
  );
};

export default connect(
  ({ analytics: { ga: { data: { gaInMarket } } } }) => ({ gaInMarket }),
)(InMarketChart);
