import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ShareOption from './shareOption';
import UpdateButton from '../UpdateButton';
import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';

import { updateEventShareOptions } from '../../../helpers/api';
import { displayErrorModal } from '../../../helpers/swal';
import { useUpdateEvent } from '../hooks';

import style from './style.module.scss';

const ACTIVE_LIST = 'active';
const INACTIVE_LIST = 'inactive';
const ACTIVE_COUNT = 4;

const updatePremium = (list, platform) => (
  list.map((opt) => {
    if (opt.platform === platform) {
      return { ...opt, premium: !opt.premium };
    }
    return opt;
  })
);

const ShareOptions = ({ selectedEvents }) => {
  const isEventUpdating = useUpdateEvent();
  const [activeOptions, setActiveOptions] = useState([]);
  const [inactiveOptions, setInactiveOptions] = useState([]);

  useEffect(() => {
    if (!isEventUpdating && selectedEvents.length) {
      const [currentEvent] = selectedEvents;
      if (currentEvent.shareOptions) {
        setActiveOptions(currentEvent.shareOptions.filter(({ enabled }) => enabled));
        setInactiveOptions(currentEvent.shareOptions.filter(({ enabled }) => !enabled));
      }
    }
  }, [isEventUpdating, selectedEvents]);

  const dragEndHandler = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    let updatedActiveOptions = activeOptions;
    let updatedInactiveOptions = inactiveOptions;
    let changedOption;

    if (source.droppableId === ACTIVE_LIST) {
      changedOption = activeOptions[source.index];
      updatedActiveOptions = [
        ...activeOptions.slice(0, source.index),
        ...activeOptions.slice(source.index + 1),
      ];
    } else if (source.droppableId === INACTIVE_LIST) {
      changedOption = inactiveOptions[source.index];
      updatedInactiveOptions = [
        ...inactiveOptions.slice(0, source.index),
        ...inactiveOptions.slice(source.index + 1),
      ];
    }

    if (changedOption && destination.droppableId === ACTIVE_LIST) {
      updatedActiveOptions = [
        ...updatedActiveOptions.slice(0, destination.index),
        { ...changedOption, enabled: true },
        ...updatedActiveOptions.slice(destination.index),
      ];
    } else if (changedOption && destination.droppableId === INACTIVE_LIST) {
      updatedInactiveOptions = [
        ...updatedInactiveOptions.slice(0, destination.index),
        { ...changedOption, enabled: false },
        ...updatedInactiveOptions.slice(destination.index),
      ];
    }

    if (updatedActiveOptions.length > ACTIVE_COUNT) {
      displayErrorModal({ text: 'You have limited share options enabled. Please, disable one of the options and try again' });
      return;
    }

    if (updatedActiveOptions.length === 0) {
      displayErrorModal({ text: 'One option need to be active at minimum' });
      return;
    }

    setActiveOptions(updatedActiveOptions);
    setInactiveOptions(updatedInactiveOptions);
  };

  const updateEventHandler = async (eventIds) => {
    const data = { eventIds, share: [...activeOptions, ...inactiveOptions] };
    return updateEventShareOptions(data);
  };

  const premiumChangeHandler = (platform, listName) => () => {
    if (listName === INACTIVE_LIST) {
      setInactiveOptions(updatePremium(inactiveOptions, platform));
    } else if (listName === ACTIVE_LIST) {
      setActiveOptions(updatePremium(activeOptions, platform));
    }
  };

  return (
    <div className={classNames('d-flex', 'flex-column', 'flex-grow-1', style.shareSettings)}>
      <div className="pageHeader">
        <h4>Share Settings</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton label="Update Event" updateHandler={updateEventHandler} />
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && (
        <div className={style.mainContent}>
          <DragDropContext onDragEnd={dragEndHandler}>
            <div className={style.sectionContainer}>
              <h5>Share Types</h5>
              <p>Drag and drop your desired options to the right side panel</p>
              <Droppable droppableId={INACTIVE_LIST}>
                {(droppableProvided) => (
                  <div ref={droppableProvided.innerRef}>
                    {inactiveOptions.map((item, index) => (
                      <Draggable
                        key={`${INACTIVE_LIST}-${item.platform}`}
                        draggableId={`${INACTIVE_LIST}-${item.platform}`}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            className={style.shareOptionContainer}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <ShareOption
                              onChange={premiumChangeHandler(item.platform, INACTIVE_LIST)}
                              sharePlatform={item.platform}
                              toggleEnabled={item.premium}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={style.sectionContainer}>
              <h5>Available to users</h5>
              <p>Rank the view for share options from left (1) to right (4).</p>
              <div className={style.activeContainer}>
                <div className={style.order}>
                  {
                    Array(ACTIVE_COUNT).fill('').map((itm, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className={style.item} key={index}>
                        <span>{index + 1}</span>
                        <div />
                      </div>
                    ))
                  }
                </div>
                <Droppable droppableId={ACTIVE_LIST}>
                  {(droppableProvided) => (
                    <div ref={droppableProvided.innerRef} className={style.dragDropContainer}>
                      {activeOptions.map((item, index) => (
                        <Draggable
                          key={`${ACTIVE_LIST}-${item.platform}`}
                          draggableId={`${ACTIVE_LIST}-${item.platform}`}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <div
                              className={style.shareOptionContainer}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <ShareOption
                                onChange={premiumChangeHandler(item.platform, ACTIVE_LIST)}
                                sharePlatform={item.platform}
                                toggleEnabled={item.premium}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </div>
      )}

      <EventSelector singleSelection showTemplate showFutureEvents />
    </div>
  );
};

export default connect(
  ({ events: { selectedEvents } }) => ({ selectedEvents }),
)(ShareOptions);
