import {
  TOGGLE_B2C_SETTINGS,
  SET_B2C_SETTINGS,
  UPDATE_B2C_SETTING,
} from './types';

const initialState = {
  enabled: false,
  currency: '',
  defaultPrice: '',
  priceList: '',
  providers: {
    stripe: true,
  },
};

export default (state = initialState, { payload, type } = {}) => {
  switch (type) {
  case SET_B2C_SETTINGS: {
    return {
      ...state,
      ...payload,
    };
  }
  case UPDATE_B2C_SETTING: {
    const [name] = Object.keys(payload);

    const newProperty = name === 'stripe' ? { providers: { ...payload } } : payload;

    return {
      ...state,
      ...newProperty,
    };
  }
  case TOGGLE_B2C_SETTINGS: {
    return {
      ...state,
      enabled: !state.enabled,
    };
  }
  default: {
    return state;
  }
  }
};
