import React, { useMemo } from 'react';

import {
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
  Col,
} from 'react-bootstrap';
import { isNil, sortBy, get } from 'lodash';

import FormInput from '../FormInput';
import HostName from './HostName';

import style from '../style.module.scss';

const CameraSettings = ({ camera }) => {
  const formFields = useMemo(() => camera.bcapConfigs && sortBy(
    camera.bcapConfigs.filter((item) => ['RADIO', 'MENU', 'RANGE', 'TIME']
      .includes(item.widgetType)), 'configKey',
  ), [camera.bcapConfigs]);

  const currentSettings = camera.cameraSettings;

  const categories = {};

  if (!camera) {
    return null;
  }

  const getDefaultValue = (item) => {
    const settingsValue = get(currentSettings, item.configKey);

    if (['RADIO', 'MENU'].includes(item.widgetType) && !Object.values(item.choices).includes(settingsValue)) {
      return item.configValue;
    }

    return settingsValue || item.configValue;
  };

  formFields.forEach((item, idx) => {
    const [category, subCategory, key, subKey] = item.configKey.split('.');

    let currentCategory = category;
    let currentLabel = subCategory;

    if (isNil(subCategory)) {
      currentCategory = 'General Settings';
      currentLabel = category;
    }

    if (category === 'camera') {
      currentCategory = isNil(key) ? 'General Settings' : subCategory;
      if (isNil(key)) {
        currentLabel = subCategory;
      } else {
        currentLabel = isNil(subKey) ? key : `${key}: ${subKey}`;
      }
    }

    if (category === 'ptu') {
      currentLabel = isNil(key) ? subCategory : `${subCategory}: ${key}`;
    }

    if (idx === 0) {
      // Hostname dose not come from DB and we want to place it on top
      categories.Device = <HostName camKey={camera.cameraKey} />;
    }

    if (isNil(categories[currentCategory])) {
      categories[currentCategory] = [];
    }

    const formGroup = (
      <Form.Group key={`${camera._id}-${item.configKey}`}>
        <Form.Row>
          <OverlayTrigger
            key={`${camera._id}-${item.configKey}-overlay`}
            placement="bottom"
            overlay={(
              <Tooltip id={`${camera._id}-${item.configKey}-tooltip`}>
                {item.configKey}
                {item.widgetType === 'RANGE' && (
                  <>
                    <br />
                    {`step: ${item.step}`}
                    <br />
                    {`min: ${item.bottom}`}
                    <br />
                    {`max: ${item.top}`}
                  </>
                )}
              </Tooltip>
            )}
          >
            <Form.Label column md={6}>
              {currentLabel}
            </Form.Label>
          </OverlayTrigger>
          <Col md={6}>
            <FormInput
              item={item}
              form="cameraSettings"
              defaultValue={getDefaultValue(item)}
            />
          </Col>
        </Form.Row>
      </Form.Group>
    );

    categories[currentCategory].push(formGroup);
  });

  return Object.keys(categories).map((category) => (
    <div className={style.FormContainer} key={category}>
      <Card>
        <Card.Header>
          {category}
        </Card.Header>
        <Card.Body>
          {categories[category]}
        </Card.Body>
      </Card>
    </div>
  ));
};

export default CameraSettings;
