import React from 'react';
import { Link } from 'react-router-dom';

import notFoundImage from '../../assets/images/404.svg';

import style from './style.module.scss';

function NotFound() {
  return (
    <div className={style.pageContainer}>
      <div className={style.content}>
        <img src={notFoundImage} alt="Not Found" />

        <p>Sorry, camera repair!, The page is not found.</p>

        <Link to="/" className="logo-container">
          <button type="button">
            Return to Home Page
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
