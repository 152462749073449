import React, { useState, useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import { range, random, isNil } from 'lodash';
import { Event, Socket } from 'react-socket-io';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import banner from '../../assets/slideshow/bannerBrizi.png';
import style from './style.module.scss';

import { getSlideshowImages } from './api';
import { urls } from '../../helpers/urls';

const { websocket } = urls;

let sliderAvailableIndexes = [];
let currentLeftInd = 0;
let currentRightInd = 1;

const defaultSettings = {
  transitionTime: 5000,
  animation: 'ease-in-out',
  backgroundColor: '#fff',
  slideshowBannerUrl: banner,
};

const Slideshow = () => {
  const [images, setImages] = useState(null);
  const [hasImages, setHasImages] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);

  const leftImageGallery = useRef();
  const rightImageGallery = useRef();
  const slideshowContainer = useRef();

  const refreshAvailableArray = () => {
    sliderAvailableIndexes = range(images.length);
    if (images.length > 3) {
      sliderAvailableIndexes.filter((n) => n !== currentLeftInd && n !== currentRightInd);
    }
  };

  const fetchFavorited = async () => {
    try {
      const { snapshots, slideshowSettings } = await getSlideshowImages();
      if (!isNil(slideshowSettings)) {
        setSettings(slideshowSettings);
      }

      if (!snapshots.length) {
        setHasImages(false);
        return;
      }
      const imagesTemp = snapshots.map(({ sharedURI, mediumOverlaidURI }) => ({
        originalClass: 'imageClass',
        thumbnailClass: 'thumbClass',
        original: sharedURI,
        thumbnail: mediumOverlaidURI,
      }));

      setImages(imagesTemp);

      if (imagesTemp.length > 1) {
        currentLeftInd = 0;
        currentRightInd = 1;
      } else if (imagesTemp.length > 0) {
        currentLeftInd = 0;
      }

      setHasImages(true);
    } catch (err) {
      console.error('Error trying to retrieve slideshow images', err);
      setHasImages(false);
    }
  };

  useEffect(() => {
    if (images) {
      refreshAvailableArray();
    }
  }, [images]);

  useEffect(() => {
    slideshowContainer.current.style.setProperty('--slideshow-bg-color', settings.backgroundColor);
    slideshowContainer.current.style.setProperty('--slideshow-transition', settings.animation);
  }, [settings]);

  useEffect(() => {
    fetchFavorited();
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (images && images.length > 2) {
      const interval = setInterval(() => {
        if (leftImageGallery.current) {
          const availableIndexes = sliderAvailableIndexes
            .filter((n) => n !== currentLeftInd);
          const nextInd = availableIndexes[random([availableIndexes.length - 1])];
          sliderAvailableIndexes = sliderAvailableIndexes.filter((n) => n !== nextInd);

          if (sliderAvailableIndexes.length === 0) {
            refreshAvailableArray();
          }

          if (nextInd !== undefined) {
            currentLeftInd = nextInd;
            leftImageGallery.current.slideToIndex(nextInd);
          }
        }

        if (rightImageGallery.current) {
          const availableIndexes = sliderAvailableIndexes
            .filter((n) => n !== currentRightInd && n !== currentLeftInd);
          const nextInd = availableIndexes[random([availableIndexes.length - 1])];
          sliderAvailableIndexes = sliderAvailableIndexes.filter((n) => n !== nextInd);

          if (sliderAvailableIndexes.length === 0) {
            refreshAvailableArray();
          }

          if (nextInd !== undefined) {
            currentRightInd = nextInd;
            rightImageGallery.current.slideToIndex(nextInd);
          }
        }
      }, settings.transitionTime);

      return () => clearInterval(interval);
    }
  }, [images]);

  return (
    <Socket uri={websocket} options={{ transports: ['websocket'] }}>
      <div className={style.SlideshowPage} ref={slideshowContainer}>
        <Event event="monitor favoritedSnapshots" handler={fetchFavorited} />

        {hasImages === null && (
          <div className={style.LoadingContainer}>
            LOADING
          </div>
        )}

        {hasImages === false && (<div className={style.NoImageContainer}>NO IMAGES AVAILABLE</div>)}

        {hasImages && (
          <>
            <div className={style.SlideshowContainer}>

              {images && images.length > 0 && (
                <ImageGallery
                  ref={leftImageGallery}
                  items={images}
                  additionalClass={style.BriziSlideShow}
                  showNav={false}
                  autoPlay={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showThumbnails={false}
                />
              )}

              {images && images.length > 1 && (
                <ImageGallery
                  ref={rightImageGallery}
                  items={images}
                  startIndex={1}
                  additionalClass={style.BriziSlideShow}
                  showNav={false}
                  autoPlay={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showThumbnails={false}
                />
              )}

            </div>
            <div className={style.CTA}>
              <img src={settings.slideshowBannerUrl} alt="banner" />
            </div>
          </>
        )}
      </div>
    </Socket>
  );
};

export default Slideshow;
