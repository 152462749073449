import React, { useEffect } from 'react';
import { Accordion, Card, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';

import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import UpdateButton from '../UpdateButton';
import B2CToggle from './B2CToggle';
import B2CForm from './B2CForm';

import { setB2CSettings } from './actions';
import { updateB2CSettings } from './apis';
import { useUpdateEvent } from '../hooks';

import style from './style.module.scss';

const B2C = ({
  event,
  b2c,
  b2cEnabled,
  setB2CSettingsAction,
}) => {
  const isEventUpdating = useUpdateEvent();

  useEffect(() => {
    if (event && !isEventUpdating) {
      setB2CSettingsAction(event.payment);
    }
  }, [event, isEventUpdating]);

  const updateEventHandler = (eventIds) => {
    const data = {
      eventIds,
      payment: b2c,
    };

    return updateB2CSettings(data);
  };


  return (
    <div className={classNames('d-flex', 'flex-column', 'flex-grow-1', style.B2CSettings)}>
      <div className="pageHeader">
        <h4>B2C</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton label="Update Event" updateHandler={updateEventHandler} />
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && (
        <Accordion activeKey={b2cEnabled ? '0' : null} className={style.formWrapper}>
          <Card>
            <Card.Header className={style.accordionHeader}>
              <h3>B2C Settings</h3>
              <B2CToggle eventKey="0" />
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-0">
                <B2CForm />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}

      <EventSelector singleSelection showTemplate showFutureEvents />
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents },
    b2c,
  }) => ({
    b2cEnabled: b2c.enabled,
    b2c,
    event: selectedEvents[0],
  }), {
    setB2CSettingsAction: setB2CSettings,
  },
)(B2C);
