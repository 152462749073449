import moment from 'moment';
import 'moment-timezone';
import { get } from 'lodash';

import { listEvents, getCurrentEvent } from '../../helpers/api';
import { updateOverlays } from './overlays';
import { dateTimeFormat } from '../../helpers/constants';
import {
  FETCH_EVENTS,
  SET_SELECTED_EVENTS,
  SET_CURRENT_EVENT,
  RESET_SELECTED_EVENTS,
  UPDATE_EVENT,
} from '../types/events';

const mapEventFn = (event) => {
  const startTimeM = moment.tz(event.startTime, event.timezone);
  const endTimeM = moment.tz(event.endTime, event.timezone);
  const isNextDay = endTimeM.diff(startTimeM, 'minutes') < 0;
  const gameDate = moment.tz(event.gameDate, event.timezone);
  const gameDateStr = gameDate.format('ddd DD/MM/YYYY');
  const currentDate = moment.tz(new Date(), event.timezone);

  return {
    _id: event._id,
    start: gameDate.format(dateTimeFormat),
    end: gameDate.format(dateTimeFormat),
    startTimeObject: startTimeM.toDate(),
    startTime: startTimeM.format('hh:mma'),
    endTimeObject: endTimeM.toDate(),
    endTime: endTimeM.format('hh:mma'),
    title: event.title,
    eventStatus: event.eventStatus,
    slugDate: event.slugDate,
    slug: event.slug,
    overlays: event.overlays,
    isNextDay,
    gameDate,
    gameDateStr,
    timezone: event.timezone,
    template: event.template,
    b2c: get(event, 'payment.enabled'),
    payment: event.payment,
    isFutureEvent: gameDate.startOf('day').isAfter(currentDate.startOf('day')),
    shareOptions: event.share,
    geofence: event.geofence,
    languages: event.languages,
    defaultLanguage: event.defaultLanguage,
    availableLanguages: event.availableLanguages,
    images: event.images,
    venueType: get(event, 'venueType', null),
    theme: event.theme,
    iconColor: event.iconColor,
    emailSettings: event.emailSettings,
    seatOptions: event.seatType,
    blockedSeats: event.blockedSeats,
    screenshots: event.screenshots,
    triviaEnabled: event.triviaEnabled,
    slideshow: event.slideshow,
    screenshotsHash: event.screenshotsHash,
  };
};

export const clearEventForm = () => ({ type: 'CLEAR_EVENT_FORM' });

export const setEventForm = (payload) => ({ type: 'SET_EVENT_FORM', payload });

export const onInputChange = (payload) => ({ type: 'ON_INPUT_CHANGE', payload });

export const setEventTimezone = (payload) => ({ type: 'SET_TIMEZONE', payload });

export const setEventSlug = (payload) => ({ type: 'SET_EVENT_SLUG', payload });

export const setEventTemplate = (payload) => ({ type: 'SET_EVENT_TEMPLATE', payload: mapEventFn(payload) });

export const setSelectedEvents = (events, selectedEventsPage) => ({
  type: SET_SELECTED_EVENTS,
  payload: {
    eventsIds: events.map(({ _id }) => _id),
    selectedEventsPage,
  },
});

const requestEvents = () => ({ type: FETCH_EVENTS });

// NOTE: this is exported only for tests
export const setEvents = (events) => {
  const data = events.map(mapEventFn);

  return {
    type: FETCH_EVENTS,
    payload: { data },
  };
};

export const setCurrentEvent = (data = null) => ({
  type: SET_CURRENT_EVENT,
  payload: { data },
});

const setEventsError = (error) => ({
  type: FETCH_EVENTS,
  payload: { error },
});

export const fetchEvents = () => async (dispatch) => {
  dispatch(requestEvents());

  try {
    const events = await listEvents();
    const overlays = [];

    events.forEach(({ overlays: eventOverlays = [] }) => {
      overlays.push(...eventOverlays);
    });

    dispatch(setEvents(events));
    dispatch(updateOverlays(overlays));
  } catch (err) {
    dispatch(setEventsError(err));
  }
};

export const fetchCurrentEvent = () => async (dispatch) => {
  try {
    const event = await getCurrentEvent([
      'title',
      'slugDate',
      'eventStatus',
      'gameDate',
      'startTime',
      'endTime',
      'timezone',
      'promo',
    ]);
    dispatch(setCurrentEvent(event));
  } catch (e) {
    console.error('Failed to fetch current event', e);
  }
};

const resetSelected = (selectedEventsPage) => ({
  type: RESET_SELECTED_EVENTS,
  payload: { selectedEventsPage },
});

export const resetSelectedEvents = (page) => (dispatch, getState) => {
  const { events: { selectedEventsPage } } = getState();

  if (page !== selectedEventsPage) {
    dispatch(resetSelected(page));
  }
};

export const updateEvent = (event) => ({
  type: UPDATE_EVENT,
  payload: { event: mapEventFn(event) },
});
