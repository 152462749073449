import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const CheckIcon = ({ onClick, checked, inverted }) => (
  <div className={style.checkIcon}>
    {/* eslint-disable-next-line  */}
    <i onClick={onClick} className={classnames({
      'fas fa-check-circle unselected': !checked && !inverted,
      'fas fa-check-circle unselected inverted': !checked && inverted,
      'fas fa-check-circle selected': checked && !inverted,
      'fas fa-check-circle text-white': checked && inverted,
    })}
    />
  </div>
);

export default CheckIcon;
