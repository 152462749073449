import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAlarmSnooze,
  faImagePolaroid,
  faBan,
} from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

import style from './style.module.scss';
import CloseBtn from './CloseBtn';
import BanBtn from './BanBtn';
import PhotoBtn from './PhotoBtn';
import SnoozeBtn from './SnoozeBtn';
import Info from './Info';

const Notification = ({
  id: notificationId,
  addressed,
  userId,
  snapshotsTaken,
  uniqueSeats,
  allSRS,
}) => (
  <div className={classnames(style.notification, { [style.addressed]: addressed })}>
    <div className="p-3 d-flex align-items-center">
      <Info allSRS={allSRS} addressed={addressed} />
    </div>

    <div className={style.data}>
      <p>
        <strong>UserID:</strong>
        &nbsp;
        <span>{userId}</span>
      </p>
      <p>
        <strong>Unique Seats:</strong>
        &nbsp;
        <span>{uniqueSeats}</span>
      </p>
      <p>
        <strong>Photos:</strong>
        &nbsp;
        <span>{snapshotsTaken}</span>
      </p>
    </div>

    <div className={style.actions}>
      <SnoozeBtn title="Snooze" notificationId={notificationId} userId={userId}>
        <FontAwesomeIcon icon={faAlarmSnooze} />
      </SnoozeBtn>

      <PhotoBtn title="Open User Photos" userId={userId}>
        <FontAwesomeIcon icon={faImagePolaroid} />
      </PhotoBtn>

      <BanBtn title="Ban User" notificationId={notificationId} userId={userId}>
        <FontAwesomeIcon icon={faBan} />
      </BanBtn>
    </div>

    <CloseBtn notificationId={notificationId} disabled={addressed} />
  </div>
);

export default Notification;
