import React from 'react';
import { connect } from 'react-redux';

import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import {
  Modal,
  Button,
  Form,
} from 'react-bootstrap';

import { addLocationOption, closeLocationModal } from '../actions';

const defaultValues = {
  location: '',
  allow: false,
};

const LocationOptionModal = ({
  isLocationModalOpen,
  closeLocationModalAction,
  addLocationOptionAction,
  locations = [],
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearError,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const handleClose = () => {
    closeLocationModalAction();
    reset(defaultValues);
  };

  const handleSubmitFn = (data) => {
    addLocationOptionAction(data);
    handleClose();
  };

  const verifyDuplicated = (evt) => {
    const { value } = evt.target;
    const isDuplicated = locations.some((item) => item.location === value);

    if (!isDuplicated) {
      return clearError('location');
    }

    return setError('location', 'duplicated', 'This value already exists, please, try a new one');
  };

  const canSubmit = !errors.location && getValues().location;

  return (
    <Modal
      show={isLocationModalOpen}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3" className="w-100 text-center">Add location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleSubmitFn)}>
          <Form.Group md="2" controlId="location">
            <Form.Label>Location</Form.Label>
            <RHFInput
              name="location"
              as={(
                <Form.Control
                  as="input"
                  type="text"
                  isInvalid={errors.location}
                />
              )}
              register={register({ required: true })}
              setValue={setValue}
              onChange={(evt) => verifyDuplicated(evt)}
            />
            {errors.location && <Form.Control.Feedback type="invalid">{errors.location.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group md="2">
            <RHFInput
              name="allow"
              setValue={setValue}
              onChange={(event) => setValue('allow', event.target.checked)}
              as={(
                <Form.Check
                  custom
                  type="checkbox"
                  id="allow"
                  name="allow"
                  label="Allow access"
                />
              )}
              register={register}
              defaultValue={false}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="small" variant="outline-primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="small" variant="primary" type="submit" onClick={handleSubmit(handleSubmitFn)} disabled={!canSubmit}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  ({
    location: { isLocationModalOpen, geofence: { locations } },
  }) => ({
    isLocationModalOpen,
    locations,
  }), {
    closeLocationModalAction: closeLocationModal,
    addLocationOptionAction: addLocationOption,
  },
)(LocationOptionModal);
