import React from 'react';
import { connect } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { GA_CHARTS_RESULTS } from '../consts';

const AffinityChart = ({ gaAffinity }) => {
  const chartData = gaAffinity.slice(0, GA_CHARTS_RESULTS).map((item) => ({
    name: item.category,
    percentage: item.value,
    label: `${item.value.toFixed(2)}%`,
  }));

  return (
    <GAChartWrapper
      cardTitle={(
        <Info
          label="Affinity"
          content="The percentage of users who have an affinity toward a market based on Google Analytics data. Affinity categories are used to reach potential customers to make them aware of your brand or product"
          className="ml-2"
        />
      )}
      chartData={chartData}
      fallbackTitle="affinity"
      chartType="horizontal"
    />
  );
};

export default connect(
  ({ analytics: { ga: { data: { gaAffinity } } } }) => ({ gaAffinity }),
)(AffinityChart);
