import {
  patch,
} from '../../../helpers/fetch';

import { buildURL, urls } from '../../../helpers/urls';

// eslint-disable-next-line import/prefer-default-export
export const updateB2CSettings = (event) => {
  const url = buildURL(urls.events.root, 'b2c');
  return patch(url, event);
};
