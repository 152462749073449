import React from 'react';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import AgeChart from './AgeChart';
import GenderChart from './GenderChart';
import InMarketChart from './InMarketChart';
import AffinityChart from './AffinityChart';
import RegionsChart from './RegionsChart';

import Loader from '../../../shared/Loader';
import Info from '../../../components/Info';

const GASection = ({ data, error, isFetching }) => {
  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        Error querying Google Analytics Data for the selected event
      </div>
    );
  }

  if (!isNil(data) && !isEmpty(data)) {
    return (
      <div className="print-break-page bigger-text-on-print">
        <div className="mt-4">
          <h4>
            Google Analytics Data
            <Info content="Analyze users by age, gender, and interest categories based on Google Analytics data. The data is aggregated and non-personally identifiable" className="ml-2" />
          </h4>
          <Row className="mt-4">
            <Col md="8">
              <AgeChart />
            </Col>
            <Col md="4">
              <GenderChart />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              <InMarketChart />
            </Col>
            <Col md="6">
              <AffinityChart />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="12">
              <RegionsChart />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return null;
};

export default connect(
  ({ analytics: { ga: { isFetching, data, error } } }) => ({ isFetching, data, error }),
)(GASection);
