import React, { memo } from 'react';
import classNames from 'classnames';

import Image from './Image';
import Ban from './ActionButtons/Ban';
import Delete from './ActionButtons/Delete';
import Download from './ActionButtons/Download';
import Favorite from './ActionButtons/Favorite';
import Select from './ActionButtons/Select';
import Share from './ActionButtons/Share';
import Description from './Description';
import Authorize from '../Authorize';

import { PHOTOS_WRITE } from '../Authorize/permissions/resources';
import { DELETE_PHOTO, BAN_PHOTO_USER, SELECT_PHOTO } from './permissions';

import '../../vendor/libs/sweetalert2/sweetalert2.scss';
import './style.scss';

const Snapshot = ({
  children,
  snapshot: {
    _id: snapshotId,
    thumbURI: image,
    rawCroppedURI: rawImage,
    sharedURI: overlayedImage,
    hidden: banned,
    userId,
    favorite,
    postObj,
    magicshot,
    section,
    row,
    seat,
    date,
  },
  selected,
  controls,
  sidebar,
  descriptionEnabled,
  onClick,
}) => (
  // eslint-disable-next-line
  <div
    onClick={() => onClick(snapshotId)}
    className={classNames('snapshotContainer', { selected, banned })}
  >

    <Image src={image} />

    {children}

    <Description
      show={descriptionEnabled}
      section={section}
      row={row}
      seat={seat}
      date={date}
    />

    <div className="actionPanel">
      <div className="upperControls">
        <Authorize permissions={SELECT_PHOTO}>
          <Select snapshotId={snapshotId} show={controls} />
        </Authorize>
        <Favorite snapshotId={snapshotId} favorite={favorite} show={sidebar} />
        <Share count={postObj.length} show={!descriptionEnabled} />
      </div>

      <div className="bottomControls">
        <Favorite snapshotId={snapshotId} favorite={favorite} show={controls && !magicshot} />
        <Download overlayed={overlayedImage} raw={rawImage} show={controls || magicshot} />
        <Authorize permissions={BAN_PHOTO_USER}>
          <Ban
            banned={banned}
            snapshotId={snapshotId}
            userId={userId}
            show={!magicshot && (controls || sidebar)}
          />
        </Authorize>
        <Authorize or permissions={[PHOTOS_WRITE, DELETE_PHOTO]}>
          <Delete snapshotId={snapshotId} image={image} show={controls || sidebar} />
        </Authorize>
      </div>
    </div>

    <div className="checked" />
  </div>
);

export default memo(Snapshot);
