import { omit, isNil } from 'lodash';

import {
  ADD_SNAPSHOT_TO_STATS,
  REMOVE_SNAPSHOTS_FROM_STATS,
  INCREMENT_SHARED,
} from '../types';

import {
  addSnapshot as addToSnapshots,
  removeSnapshots as removeFromSnapshots,
  setSharedSnapshot,
} from '../../../store/actions/snapshots';
import { setListItemsFilterData, setFullscreenSnapshot } from '../../../components/Snapshot/LightBox/actions';
import { fetchSnapshotStats } from '../actions';

const addSnapshotToStats = (snapshot) => ({
  type: ADD_SNAPSHOT_TO_STATS,
  payload: snapshot,
});

const removeSnapshotFromStat = (snapshot) => ({
  type: REMOVE_SNAPSHOTS_FROM_STATS,
  payload: snapshot,
});

const incrementSharedCount = () => ({ type: INCREMENT_SHARED });

export const addSnapshot = (snapshot) => (dispatch, getState) => {
  const {
    events: {
      selectedEvents,
    },
    album: {
      options: {
        itemsPerPage,
        currentPage,
        currentTab,
      },
      filterForm: {
        data: filterData,
      },
    },
  } = getState();

  const eventSelected = !!selectedEvents.find(({ slugDate }) => slugDate === snapshot.slugDate);
  const hasFilters = Object.values(omit(filterData, 'seatType'))
    .some((field) => (Array.isArray(field) ? field.length > 0 : !isNil(field)));

  if (!eventSelected || hasFilters) {
    return;
  }

  dispatch(addSnapshotToStats(snapshot));

  if (currentPage === 1 && currentTab === 'all' && !snapshot.magicshot) {
    dispatch(addToSnapshots(snapshot, itemsPerPage));
  }
};

export const removeSnapshots = (snapshotIds) => (dispatch, getState) => {
  const { snapshots: { items: snapshots } } = getState();
  let all = 0;
  let favorite = 0;
  let magicshot = 0;
  let premium = 0;
  let shared = 0;
  let shouldRefreshSnapshots = false;

  snapshotIds.forEach((id) => {
    const snapshot = snapshots.find(({ _id }) => _id === id);
    if (snapshot) {
      // increment all if snapshot is not a magicshot
      all += Number(!snapshot.magicshot);
      favorite += Number(snapshot.favorite);
      magicshot += Number(snapshot.magicshot);
      premium += Number(snapshot.premium);
      shared += Number(!!snapshot.postObj.length);
    } else {
      shouldRefreshSnapshots = true;
    }
  });

  dispatch(removeFromSnapshots(snapshotIds));

  if (shouldRefreshSnapshots) {
    dispatch(fetchSnapshotStats());
  } else {
    dispatch(removeSnapshotFromStat({
      all,
      favorite,
      magicshot,
      premium,
      shared,
    }));
  }
};

export const setShared = (snapshot) => (dispatch, getState) => {
  const { slugDate } = snapshot;
  const { events: { selectedEvents } } = getState();
  const eventSelected = selectedEvents.some((event) => event.slugDate === slugDate);

  // increase only on first share
  if (snapshot.postObj.length === 1 && eventSelected) {
    dispatch(incrementSharedCount());
  }

  dispatch(setSharedSnapshot(snapshot));
};

export const setLightBoxSnapshot = (snapshotId) => (dispatch, getState) => {
  const {
    album: {
      options: {
        itemsPerPage,
        currentTab,
        currentPage,
      },
      filterForm: {
        data: filter,
      },
    },
  } = getState();
  dispatch(setFullscreenSnapshot(snapshotId));
  dispatch(setListItemsFilterData({
    filter,
    itemsPerPage,
    currentTab,
    currentPage,
  }));
};
