import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import style from './style.module.scss';

const TextAreaEditor = ({
  value,
  maxlength,
  // eslint-disable-next-line no-unused-vars
  onUpdate,
  ...rest
},
ref) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    getValue: () => inputRef.current.value,
  }));

  return [
    <textarea
      {...rest}
      ref={inputRef}
      key="textareaEditor"
      maxLength={maxlength}
      defaultValue={value}
      className={style.TextAreaEditor}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />,
  ];
};

export default forwardRef(TextAreaEditor);
