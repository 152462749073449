import React from 'react';
import { connect } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { getPercentage, capitalize } from '../../utils';

const AgeChart = ({ gaUserAge }) => {
  const totalAmmount = gaUserAge.reduce((prev, curr) => (prev + curr.y), 0);

  const chartData = gaUserAge.map((item) => ({
    name: capitalize(item.x),
    total: item.y,
    percentage: getPercentage(item.y, totalAmmount),
  }));

  return (
    <GAChartWrapper
      chartType="bar"
      cardTitle={(
        <Info
          label="Age"
          content="The number of users in each age group based on Google Analytics data"
          className="ml-2"
        />
      )}
      fallbackTitle="age"
      chartData={chartData}
    />
  );
};

export default connect(
  ({ analytics: { ga: { data: { gaUserAge } } } }) => ({ gaUserAge }),
)(AgeChart);
