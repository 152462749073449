import React from 'react';
import { Resizable } from 're-resizable';
import classNames from 'classnames';

import HandleBar from './HandleBar';

import style from './style.module.scss';

const defaultResizableOptions = {
  top: false,
  right: false,
  left: true,
  bottom: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const defaultHeight = '100%';
const defaultWidth = '10vw';
const HandleBarButton = (<HandleBar />);

const RightBar = (props) => {
  const {
    showBar = false,
    resizable = false,
    width = defaultWidth,
    height = defaultHeight,
    minWidth = defaultWidth,
    minHeight = defaultHeight,
    children = null,
    resizableOptions = {},
  } = props;

  const resizableSettings = { ...defaultResizableOptions, ...resizableOptions };
  const inlineStyle = showBar ? { minWidth, minHeight } : {};

  if (!resizable) {
    return (
      <div
        style={inlineStyle}
        className={classNames(style.RightBar, style.static, { [style.hide]: !showBar })}
      >
        {children}
      </div>
    );
  }

  return (
    <div style={inlineStyle} className={classNames(style.RightBar, { [style.hide]: !showBar })}>
      <Resizable
        width={width}
        height={height}
        enable={resizableSettings}
        handleComponent={{ left: HandleBarButton }}
        handleClasses={{ left: style.barContainer }}
        className={style.resizableComponent}
        minWidth={parseInt(minWidth, 10)}
      >
        {children}
      </Resizable>
    </div>
  );
};

export default RightBar;
