import React, { useState, useEffect } from 'react';
import { Card, Collapse, Spinner } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import { getMappingInfo } from './api';

import tableStyle from '../table.module.scss';

const mappingProperties = {
  cam: 'Camera',
  type: 'Seat Type',
  section: 'Section',
  minr: 'Min Row',
  maxr: 'Max Row',
  mins: 'Min Seat',
  maxs: 'Max Seat',
};

const columns = Object.keys(mappingProperties).map((key) => ({
  text: mappingProperties[key],
  dataField: key,
  sort: true,
  filter: textFilter(),
}));

const ParsedMappingTable = () => {
  const [mappingInfo, setMappingInfo] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setHasError(false);

      (async () => {
        try {
          const mappingData = await getMappingInfo();
          const stageMappingInfo = [];

          Object.keys(mappingData).forEach((section) => {
            mappingData[section].forEach((item) => {
              const id = `${section}-${item.cam}-${item.minr}-${item.maxr}-${item.mins}-${item.maxs}`;
              stageMappingInfo.push({ ...item, section, id });
            });
          });

          setMappingInfo(stageMappingInfo);
        } catch (err) {
          setHasError(true);
          console.error(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open]);

  return (
    <div className={classnames('my-3', tableStyle.tableWrapper)}>
      <Card>
        <Card.Header onClick={() => setOpen(!open)}>
          <h5 className="mb-0">Parsed Mapping File</h5>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classnames({ [tableStyle.rotated]: open })}
          />
        </Card.Header>
        <Collapse in={open}>
          <Card.Body className="p-0">
            {loading && (
              <div className="text-center p-4">
                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
              </div>
            )}

            {hasError && (
              <div className="p-4 text-center">
                <span>Error trying to load Mapping Info</span>
              </div>
            )}

            {!hasError && !loading && mappingInfo.length === 0 && (
              <span>No Mapping Info found</span>
            )}

            {!hasError && !loading && mappingInfo.length > 0 && (
              <ToolkitProvider
                keyField="id"
                data={mappingInfo}
                columns={columns}
                bootstrap4
              >
                {(props) => (
                  <BootstrapTable
                    {...props.baseProps}
                    wrapperClasses="table-responsive react-bs-table-card searchFilter"
                    classes="card-table border-top"
                    filter={filterFactory()}
                    pagination={paginationFactory(10)}
                    striped
                  />
                )}
              </ToolkitProvider>
            )}
          </Card.Body>
        </Collapse>
      </Card>
    </div>
  );
};

export default ParsedMappingTable;
