import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button, Spinner } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import UpdateButton from '../UpdateButton';
import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import Overlay from './Overlay';

// TODO replace success and error with the new error message
import { displayErrorModal } from '../../../helpers/swal';
import { uploadImage } from '../../../helpers/api';
import { updateEventOverlays } from './api';
import { useUpdateEvent } from '../hooks';

import style from './style.module.scss';

const emptyUniqueKeys = ['fisrt', 'second', 'third'];

const Overlays = ({
  event: {
    overlays: eventOverlays,
    slugDate,
  } = {},
}) => {
  const isEventUpdating = useUpdateEvent();
  const [overlays, setOverlays] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    if (eventOverlays && !isEventUpdating) {
      setOverlays(eventOverlays.filter(({ deleted }) => !deleted));
    }
  }, [eventOverlays, isEventUpdating]);

  const openBrowserDialog = () => fileInputRef.current.click();

  const uploadHandler = async () => {
    try {
      const [file] = fileInputRef.current.files;
      setLoading(true);
      const imageObject = await uploadImage('overlay', slugDate, file);
      setOverlays([...overlays, imageObject]);
    } catch (err) {
      const message = get(err, 'body.error');
      displayErrorModal({
        title: 'Failed to update overlays',
        text: message,
      });
      console.error(message, err);
    } finally {
      setLoading(false);
    }
  };

  const updateOverlay = (storageKey) => (obj) => {
    const updatedOverlays = overlays.map((overlay) => {
      if (overlay.storageKey === storageKey) {
        return { ...overlay, ...obj };
      }
      return overlay;
    });

    setOverlays(updatedOverlays.filter(({ deleted }) => !deleted));
  };

  const save = (eventIds) => updateEventOverlays({ eventIds, overlays });

  const dragEndHandler = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    const changedOption = overlays[source.index];

    let updatedOverlays = [
      ...overlays.slice(0, source.index),
      ...overlays.slice(source.index + 1),
    ];

    updatedOverlays = [
      ...updatedOverlays.slice(0, destination.index),
      changedOption,
      ...updatedOverlays.slice(destination.index),
    ];

    setOverlays(updatedOverlays);
  };

  const calcEmptyBlocks = () => {
    const overlayLength = overlays.length;
    let emptyBlocksArray = [];

    if (overlayLength < 3) {
      emptyBlocksArray = Array(3 - overlayLength);
    } else if (overlayLength < 7) {
      emptyBlocksArray = Array(1);
    }

    return emptyBlocksArray.fill({}).map((item, idx) => (
      <Draggable
        isDragDisabled
        index={overlayLength + idx}
        draggableId={`Empty-${idx}`}
        key={emptyUniqueKeys[idx]}
      >
        {(draggableProvided) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <Overlay
              addMode
              hide={idx !== 0}
              loading={loading && idx === 0}
              openDialog={openBrowserDialog}
            />
          </div>
        )}
      </Draggable>
    ));
  };

  return (
    <div className={classNames('d-flex', 'flex-column', 'flex-grow-1', style.overlayPage)}>
      <div className="pageHeader">
        <h4>Overlays</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton label="Update Event" updateHandler={save} />
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && (
        <div className={style.mainContent}>
          <div className={style.description}>
            <div>
              <h5>Available to users</h5>
              <p>
                The overlays will appear on the user app in the same order as
                they are displayed here, from left to right.
              </p>
            </div>
            <div>
              <input type="file" ref={fileInputRef} onChange={uploadHandler} className="d-none" />
              <Button
                variant="outline-primary"
                type="button"
                className="small text-nowrap"
                disabled={loading || overlays.length >= 7}
                onClick={openBrowserDialog}
              >
                Add Overlay
              </Button>
            </div>
          </div>
          <div className={style.overlaysContainer}>
            <DragDropContext onDragEnd={dragEndHandler}>
              <Droppable droppableId="overlays" direction="horizontal">
                {(droppableProvided) => (
                  <div ref={droppableProvided.innerRef}>
                    {overlays.map((overlay, index) => (
                      <Draggable
                        key={`${overlay.storageKey}-${overlay.premium}`}
                        draggableId={overlay.storageKey}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            className={style.showOrder}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Overlay
                              key={overlay.storageKey}
                              overlay={overlay}
                              openDialog={openBrowserDialog}
                              onChange={updateOverlay(overlay.storageKey)}
                              onDelete={updateOverlay(overlay.storageKey)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {calcEmptyBlocks()}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}

      <EventSelector singleSelection showTemplate showFutureEvents />
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents },
  }) => ({
    event: selectedEvents[0],
  }),
)(Overlays);
