import React from 'react';
import classNames from 'classnames';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import style from './style.module.scss';

const handleNavigationButton = (toolbar, direction) => toolbar.onNavigate(direction);

const ToolbarContainer = (toolbar) => (
  <div className={classNames('d-flex', 'flex-wrap', 'align-items-center', style.CalendarToolbar)}>
    <div className={style.Title}>
      <OverlayTrigger
        placement="right"
        overlay={(
          <Popover id="Calendar">
            <Popover.Content>
              Create or view an event by clicking on the date
            </Popover.Content>
          </Popover>
        )}
      >
        <span>Calendar</span>
      </OverlayTrigger>
    </div>
    <div className={style.CalendarControls}>
      <span className={style.MonthName}>
        {toolbar.label}
      </span>
      {/* eslint-disable-next-line */}
      <span onClick={() => handleNavigationButton(toolbar, 'PREV')}>
        <i className={classNames('ion', 'ion-ios-arrow-back', style.pointer)} />
      </span>
      {/* eslint-disable-next-line  */}
      <span onClick={() => handleNavigationButton(toolbar, 'NEXT')}>
        <i className={classNames('ion', 'ion-ios-arrow-forward', style.pointer)} />
      </span>
      <Button variant="outline-primary" className="small" onClick={() => handleNavigationButton(toolbar, 'TODAY')}>Today</Button>
    </div>
    <div className={style.EventStatusColor}>
      <span className="py-1 px-2">
        <i className={classNames('fas', 'fa-circle', style.StatusIcon, style.PastEvent)} />
        Past
      </span>
      <span className="py-1 px-2">
        <i className={classNames('fas', 'fa-circle', style.StatusIcon, style.LiveEvent)} />
        Live
      </span>
      <span className="py-1 px-2">
        <i className={classNames('fas', 'fa-circle', style.StatusIcon, style.UpcomingEvent)} />
        Upcoming
      </span>
    </div>
  </div>
);

export default ToolbarContainer;
