import React from 'react';
import { connect } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { getPercentage, capitalize } from '../../utils';

const GenderChart = ({ gaUserGender }) => {
  const totalAmmount = gaUserGender.reduce((prev, curr) => (prev + curr.y), 0);

  const chartData = gaUserGender.map((item) => ({
    name: capitalize(item.key),
    total: item.y,
    percentage: getPercentage(item.y, totalAmmount),
  }));

  return (
    <GAChartWrapper
      chartType="bar"
      cardTitle={(
        <Info
          label="Gender"
          content="The number of users of each gender based on Google Analytics data"
          className="ml-2"
        />
      )}
      fallbackTitle="gender"
      chartData={chartData}
    />
  );
};

export default connect(
  ({ analytics: { ga: { data: { gaUserGender } } } }) => ({ gaUserGender }),
)(GenderChart);
